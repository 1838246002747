import { BudgetDto } from 'api/budgets/models/BudgetDto';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { Label } from 'common/components/texts/Texts';
import InputError from 'common/components/inputError/InputError';
import { OptionTitleSubTitle, SearchableSelectTitleSubTitleOption } from 'common/components/searchableSelectInput/optionsFormats/OptionTitleSubTitle';
import { SelectInputController, SelectInputOption } from 'common/components/selectInput/SelectInput';
import { TextInputController } from 'common/components/textInput/TextInput';
import BudgetsService from 'api/budgets/BudgetsService';
import Loading from 'common/services/Loading';
import Toast from 'common/services/Toast';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE, MAX_DECIMAL_PLACES, MIN_DECIMAL_PLACES } from 'Config';
import { useDispatch } from 'react-redux';
import { calculateAllLinesTotals, updateAllLines, updateBudget } from 'store/budget/action';
import YesNoModal from 'common/components/modal/yesNoModal/YesNoModal';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DateInputController } from 'common/components/dateInput/DateInput';
import { TaxDto } from 'api/taxes/models/TaxDto';
import { BudgetStatus } from 'api/budgets/enums/BudgetStatus';
import { MoneyInputController } from 'common/components/moneyInput/MoneyInput';
import BudgetFileModelsService from 'api/budgetFileModels/BudgetFileModelsService';
import styles from './BudgetDetails.module.scss'
import { BudgetFieldType } from 'api/budgetsFields/enums/BudgetFieldType';

interface Props {
    externalParts: SearchableSelectTitleSubTitleOption[];
    workTypes: SelectInputOption[];
    works: SelectInputOption[];
    workContacts: SearchableSelectTitleSubTitleOption[];
    taxes: TaxDto[];
    budgetFileModels: SelectInputOption[];
    type: 'create' | 'details' | 'edit' | 'version' | undefined;
    onChangeExternalPart: (value: string | undefined | null) => void;
    getWorkContacts: (value: string | undefined | null) => void;
    onChangeWorkType: () => void;
}

function BudgetScreen({ type, externalParts, works, workContacts, workTypes, taxes, budgetFileModels, onChangeExternalPart, getWorkContacts, onChangeWorkType }: Props): JSX.Element | null {
    const { t, i18n } = useTranslation();
    const form = useFormContext<BudgetDto>();
    const dispatch = useDispatch();
    const [showUpdateLinesDiscountQuestion, setShowUpdateLinesDiscountQuestion] = useState<boolean>(false);
    const tempLinesDiscountValue = useRef<number | null>(null);
    const alreadyHadWorkId = useRef(form.getValues('workId'));
    const taxesOptions = taxes.map(t => ({ selectedLabel: t.value?.toString() + ' %', label: t.description, value: t.id }));
    const statusesOptions = useMemo(() => Object.keys(BudgetStatus).map(key => ({
        label: t('budgets.status.' + key),
        value: key,
    })), [i18n.language]);
    const { fields: fieldsValues } = useFieldArray({
        control: form.control,
        name: 'fieldsValues',
        keyName: 'key',
    });

    const isDetails = type === 'details' || type === 'version';

    const errors = form.formState.errors;

    const onClickNewWork = () => {
        window.open('/business/works/create');
    }

    const onClickDetailsWork = (workdId: string) => {
        window.open('/business/works/details/' + workdId);
    }

    const updateLinesDiscount = (highestDiscount: number) => {
        dispatch(updateAllLines({
            discountPercentage: highestDiscount,
        }));
        dispatch(calculateAllLinesTotals());
    }

    const onCloseUpdateLinesDiscountQuestion = (success: boolean) => {
        if (success && tempLinesDiscountValue.current) {
            updateLinesDiscount(tempLinesDiscountValue.current);
        }
        tempLinesDiscountValue.current = null;
        setShowUpdateLinesDiscountQuestion(false);
    }

    const onChangeWork = async (workId: string | null | undefined) => {
        getWorkContacts(workId);

        if (!workId) {
            return;
        } else {
            form.clearErrors('workId');
        }
        try {
            Loading.show();

            const workData = await BudgetsService.getWorkData(workId);
            const discounts = (workData.externalParts.filter(x => x.defaultDiscount).map(x => x.defaultDiscount) as number[]);
            const highestDiscount = discounts.length > 0 ? Math.max(...discounts) : null;

            dispatch(updateBudget({
                defaultDiscountPercentage: highestDiscount
            }));

            form.setValue('defaultDiscountPercentage', highestDiscount);

            if (highestDiscount) {
                if (alreadyHadWorkId.current && alreadyHadWorkId.current !== workId) {
                    tempLinesDiscountValue.current = highestDiscount;
                    setShowUpdateLinesDiscountQuestion(true);
                } else {
                    updateLinesDiscount(highestDiscount);
                }
            }

            alreadyHadWorkId.current = workId;
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get work data', error);
            Toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const onChangeTax = (taxId: string | null | undefined) => {
        const tax = taxes.find(x => x.id === taxId);

        dispatch(updateBudget({
            defaultTaxId: tax ? tax.id : null,
            defaultTaxValue: tax ? tax.value : null,
        }));

        if (tax) {
            dispatch(updateAllLines({
                taxId: tax.id,
                taxValue: tax.value,
            }));
            dispatch(calculateAllLinesTotals());
        }
    }

    const onChangeFileModel = async (fileModelId: string | null | undefined) => {
        if (fileModelId && !form.getValues('paymentTerms')?.length) {
            const fileModel = budgetFileModels.find(x => x.value === fileModelId);
            if (fileModel) {
                const terms = await BudgetFileModelsService.getModelPaymentTerms(fileModelId);
                form.setValue('paymentTerms', terms)
            }
        }
    }

    const onBlurDecimalPlaces = (v: number | undefined | null) => {
        dispatch(updateBudget({
            decimalPlaces: v ?? 0,
        }));
        dispatch(calculateAllLinesTotals());
    }

    const onBlurDefaultDiscountPercentage = (v: number | undefined | null) => {
        dispatch(updateBudget({
            defaultDiscountPercentage: v ?? 0,
        }));
        updateLinesDiscount(v ?? 0);
    }

    useEffect(() => {
        if (budgetFileModels && budgetFileModels.length === 1) {
            const fileModelId = budgetFileModels[0].value;
            form.setValue('budgetFileModelId', fileModelId);
            onChangeFileModel(fileModelId)
        }
    }, [budgetFileModels]);

    useEffect(() => {
        onChangeWork(form.getValues('workId'));
    }, []);

    return (
        <div className={styles.container}>
            <Row className="mb-3">
                <Col sm={12} md={3} xl={2}>
                    <Label space>{t('budgets.budget.number')}</Label>
                    <TextInputController
                        name='number'
                        control={form.control}
                        placeholder={t('budgets.budget.number')}
                        disabled={true}
                        rules={{ required: true, maxLength: 200 }}
                        hasError={Boolean(errors.number)}
                    />
                    <InputError error={errors.number} maxLength={200} />
                </Col>
                <Col sm={12} md={3} xl={2}>
                    <Label space>{t('budgets.budget.version')}</Label>
                    <TextInputController
                        name='budgetHistoryVersion'
                        placeholder={t('budgets.budget.version')}
                        control={form.control}
                        disabled={true}
                    />
                </Col>
                <Col sm={12} md={6} xl={8}>
                    <Label space>{t('budgets.budget.description')}{!isDetails ? '*' : ''}</Label>
                    <TextInputController
                        name='description'
                        control={form.control}
                        placeholder={t('budgets.budget.description')}
                        disabled={isDetails}
                        rules={{ required: true, maxLength: 500 }}
                        hasError={Boolean(errors.description)}
                    />
                    <InputError error={errors.description} maxLength={500} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.external_part')}{!isDetails && !form.watch('workId') ? '*' : ''}</Label>
                    <SelectInputController
                        name='externalPartId'
                        control={form.control}
                        rules={{ required: !form.watch('workId') }}
                        hasError={Boolean(errors.externalPartId)}
                        options={externalParts}
                        formatOptionLabel={OptionTitleSubTitle}
                        onChange={onChangeExternalPart}
                        disabled={isDetails}
                        smallerValueContainer
                    />
                    <InputError error={errors.externalPartId} />
                </Col>
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.work')}{!isDetails ? '*' : ''}</Label>
                    <SelectInputController
                        name='workId'
                        control={form.control}
                        rules={{ required: true }}
                        hasError={Boolean(errors.workId)}
                        disabled={(!form.watch('externalPartId') && type === 'create') || isDetails}
                        options={works}
                        smallerValueContainer
                        formatOptionLabel={OptionTitleSubTitle}
                        onClickAddNew={onClickNewWork}
                        onClickGoToDetails={onClickDetailsWork}
                        onChange={onChangeWork}
                    />
                    <InputError error={errors.workId} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.works_types')}</Label>
                    <SelectInputController
                        name='workTypeId'
                        control={form.control}
                        options={workTypes}
                        disabled={isDetails}
                        onChange={onChangeWorkType}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.decimal_places')}</Label>
                    <MoneyInputController
                        name="decimalPlaces"
                        disabled={isDetails}
                        control={form.control}
                        decimalScale={0}
                        hideUnitsDrop
                        onBlur={onBlurDecimalPlaces}
                        isAllowed={({ floatValue }) => {
                            return (
                                !!floatValue && (floatValue >= MIN_DECIMAL_PLACES && floatValue <= MAX_DECIMAL_PLACES)
                            );
                        }}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.date')}</Label>
                    <DateInputController
                        name='date'
                        control={form.control}
                        disabled={isDetails}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.validation_date')}</Label>
                    <DateInputController
                        name='validationDate'
                        control={form.control}
                        disabled={isDetails}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.default_tax')}</Label>
                    <SelectInputController
                        name='defaultTaxId'
                        control={form.control}
                        options={taxesOptions}
                        disabled={isDetails}
                        onChange={onChangeTax}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.default_remise')}</Label>
                    <MoneyInputController
                        name='defaultDiscountPercentage'
                        control={form.control}
                        unitPrefix="%"
                        disabled={isDetails}
                        onBlur={onBlurDefaultDiscountPercentage}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.on_site_contact')}</Label>
                    <SelectInputController
                        name='onSiteContactId'
                        control={form.control}
                        rules={{ required: false }}
                        hasError={Boolean(errors.onSiteContactId)}
                        disabled={(!form.watch('workId') && type === 'create') || isDetails}
                        options={workContacts}
                        smallerValueContainer
                        formatOptionLabel={OptionTitleSubTitle}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.manager_contact')}</Label>
                    <SelectInputController
                        name='managerContactId'
                        control={form.control}
                        rules={{ required: false }}
                        hasError={Boolean(errors.managerContactId)}
                        disabled={(!form.watch('workId') && type === 'create') || isDetails}
                        options={workContacts}
                        smallerValueContainer
                        formatOptionLabel={OptionTitleSubTitle}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.status')}</Label>
                    <SelectInputController
                        name='status'
                        control={form.control}
                        options={statusesOptions}
                        disabled={isDetails}
                        removeRemoveButton
                    />
                </Col>
                <Col sm={12} md={6}>
                    <Label space>{t('budgets.budget.budget_file_model')}</Label>
                    <SelectInputController
                        name='budgetFileModelId'
                        control={form.control}
                        options={budgetFileModels}
                        disabled={isDetails}
                        onChange={onChangeFileModel}
                    />
                </Col>
            </Row>
            <Row>
                {fieldsValues.map((fv, index) => (
                    <Col sm={12} md={6} key={fv.key} className="mb-3">
                        <Label space>{fv.name}{!isDetails && fv.isRequired ? '*' : ''}</Label>
                        {fv.fieldType === BudgetFieldType.SELECT && (
                            <SelectInputController
                                name={`fieldsValues.${index}.budgetFieldOptionId`}
                                control={form.control}
                                options={fv.options.map(op => ({ label: op.label, value: op.id! }))}
                                disabled={isDetails}
                                rules={{ required: fv.isRequired }}
                                hasError={Boolean(errors.fieldsValues ? errors.fieldsValues[index]?.budgetFieldOptionId : undefined)}
                            />)}
                        {fv.fieldType === BudgetFieldType.TEXT && (
                            <TextInputController
                                name={`fieldsValues.${index}.value`}
                                control={form.control}
                                disabled={isDetails}
                                rules={{ required: fv.isRequired }}
                                hasError={Boolean(errors.fieldsValues ? errors.fieldsValues[index]?.value : undefined)}
                                placeholder={t('common.write_here')}
                            />)}
                        {fv.fieldType === BudgetFieldType.NUMBER && (
                            <MoneyInputController
                                name={`fieldsValues.${index}.value`}
                                control={form.control}
                                disabled={isDetails}
                                rules={{ required: fv.isRequired }}
                                hasError={Boolean(errors.fieldsValues ? errors.fieldsValues[index]?.value : undefined)}
                                hideUnitsDrop
                            />)}
                        {fv.fieldType === BudgetFieldType.SELECT && <InputError error={errors.fieldsValues ? errors.fieldsValues[index]?.budgetFieldOptionId : undefined} />}
                        {fv.fieldType !== BudgetFieldType.SELECT && <InputError error={errors.fieldsValues ? errors.fieldsValues[index]?.value : undefined} />}
                    </Col>
                ))}
            </Row>

            <YesNoModal
                isOpen={showUpdateLinesDiscountQuestion}
                title={t('budgets.budget.update_remise_all_lines_title')}
                message={t('budgets.budget.update_remise_all_lines_message')}
                onClose={onCloseUpdateLinesDiscountQuestion}
            />
        </div>
    );
}

export default BudgetScreen;
